/* @flow */
import * as React from 'react';
import { Fragment } from 'react'
import Helmet from 'react-helmet'


import cx from 'classnames'
import {
  square_container,
  square_row,
  square_column,
  square_box,
  square_box_3,
  square_box_4,
} from './styles.module.css'


import Nav from '../layouts/nav';
import Footer from '../layouts/footer';

type Props = {
  children: React.ReactNode,
  path?: string,
}

const Layout = ({ children }: Props): React.ReactNode => (
  <Fragment>
    <Helmet>
      <title>photocompetion.au - managed photo competition platform.</title>
      <meta charSet='utf-8' />
      <link rel='canonical' href='https://photocompetion.au' />
    </Helmet>
    <Nav />
    {children}
    <Footer />
  </Fragment>
)


type SquareProps = { children?: React.ReactNode, className?: any }

export const SquareContainer = ({ children, className }: SquareProps): React.ReactNode => (
  <div className={cx(square_container, className)}>{children}</div>
)

export const SquareRow = ({ children }: { children?: React.ReactNode }): React.ReactNode => (
  <div className={cx(square_row)}>{children}</div>
)

export const SquareColumn = ({ children }: { children?: React.ReactNode }): React.ReactNode => (
  <div className={cx(square_column)}>{children}</div>
)


type BoxProps = { children?: React.ReactNode, className?: any, small?: boolean, mid?: boolean, id?: string }
export const SquareBox = ({ children, className, small, mid, ...rest }: BoxProps): React.ReactNode => (
  <div className={cx(square_box, small && square_box_4, mid && square_box_3, className)} {...rest}><div>{children}</div></div>
)


export default Layout
