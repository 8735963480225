// extracted by mini-css-extract-plugin
export var NavLangItem = "styles-module--NavLangItem--f16eb";
export var NavOpen = "styles-module--NavOpen--eda3d";
export var NavSection = "styles-module--NavSection--1d23b";
export var NavSep = "styles-module--NavSep--5e5c0";
export var activeNavLink = "styles-module--activeNavLink--c7641";
export var blend = "styles-module--blend--5c4ea";
export var blinker = "styles-module--blinker--77d27";
export var gallery = "styles-module--gallery--f59aa";
export var gallery_cell = "styles-module--gallery_cell--4db20";
export var hero = "styles-module--hero--0299f";
export var hide_on_mobile = "styles-module--hide_on_mobile--24bf4";
export var home_cta = "styles-module--home_cta--59e08";
export var home_cta_flat = "styles-module--home_cta_flat--f37e6";
export var index_fit = "styles-module--index_fit--6fe2e";
export var link_tag = "styles-module--link_tag--f4877";
export var section_footer = "styles-module--section_footer--c3a69";
export var square_box = "styles-module--square_box--65c61";
export var square_box_3 = "styles-module--square_box_3--5bfe9";
export var square_box_4 = "styles-module--square_box_4--99ce1";
export var square_column = "styles-module--square_column--630c8";
export var square_container = "styles-module--square_container--2afbb";
export var square_row = "styles-module--square_row--cd8c2";