/* @flow */
import React from 'react';
import { Link } from 'gatsby-link'
import { StaticImage } from 'gatsby-plugin-image'

import Helmet from 'react-helmet'

import cx from 'classnames'

import { NavSection } from './styles.module.css'



const logo = '../images/branding/mark_white.png'

const Nav = () => {


  return (
    <section className={cx(NavSection)}>
      <nav>
        <Link to='/'>
          <StaticImage objectFit='contain' width={120} data-loading='eager' alt='photo compeition .au' src={logo} />
        </Link>
      </nav>
    </section>
  )
}
export default Nav
